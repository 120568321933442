
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon
  },
  emits: ["close", "clickFileInputRef", "exportAllStudents"]
})
export default class MsCsvUUpdateModal extends Vue {
  startUpdating() {
    this.$emit("clickFileInputRef");
  }

  exportStudents() {
    this.$emit("exportAllStudents");
  }

  close() {
    this.$emit("close");
  }
}
